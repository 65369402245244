import React from 'react';
import './Card.scss';




function Card( { icon, title, description, }) {
  return (
    <>
    <div className="card">
      <div className="card__container">
        <div className="card__icon">
          {icon}
        </div>
        <div className="card__info">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Card