import React from 'react';
import './Feed.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { MdOutlineCleanHands, MdOutlineCleaningServices } from "react-icons/md";
import { useUserAuth } from '../../context/UserAuthContext';

function Feed() {

  const data = [
    {
     key: '1',
     path: '/higiene-manos',
     icon: <MdOutlineCleanHands/>,
     title: 'Higiene de manos',
     description: 'Estrategia multimodal',
    },
    {
     key: '2',
     path: '/asepsia-antiasepsia',
     icon: <MdOutlineCleaningServices/>,
     title: 'Asepsia y antiasepsia',
     description: 'Estrategia multimodal',
     },
  ]

  const { user } = useUserAuth();

  return (
    <>
    <Topbar
      user='Hospital Civil de Ipiales'
    />
    <div className='feed'>
        <h2>Bienvenido(a)</h2>
        <br />
        <p>{user.email}</p>
      <div className="feed__container">
          <h3>Elige la actividad</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <Link to={item.path} style={{textDecoration: 'none'}} >
                  <Card
                      key={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                </Link>
                 
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Feed