import React from 'react';
import './Navbar.scss'; 
import { Link } from 'react-router-dom';
import { MdOutlineHome, MdOutlineSupportAgent, MdPersonOutline } from 'react-icons/md';

function Navbar() {
  return (
    <div className="navbar">
        <Link to='/feed' className="navbar__item">
          <i><MdOutlineHome size='30px'/></i>
          <span>Inicio</span>
        </Link>
        <Link to='/soporte' className="navbar__item">
            <i><MdOutlineSupportAgent size='30px'/></i>
            <span>Soporte</span>
        </Link>
        <Link to='/perfil' className="navbar__item">
              <i><MdPersonOutline size='30px'/></i>
              <span>Perfil</span>
        </Link>
    </div>
  )
}

export default Navbar;