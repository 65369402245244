import React, { useState } from 'react';
import '../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import Navbar from '../../../components/navbar/Navbar';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import Swal from 'sweetalert2'
import { db } from '../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore'
import { useUserAuth } from '../../../context/UserAuthContext';


function Form() {
  const [servicio, setServicio] = useState('');
  const [cargo, setCargo] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [aspecto1, setAspecto1] = useState('');
  const [aspecto2, setAspecto2] = useState('');
  const [aspecto3, setAspecto3] = useState('');
  const [aspecto4, setAspecto4] = useState('');
  const [aspecto5, setAspecto5] = useState('');
  const [momento1, setMomento1] = useState('');
  const [tecnica1, setTecnica1] = useState('');
  const [momento2, setMomento2] = useState('');
  const [tecnica2, setTecnica2] = useState('');
  const [momento3, setMomento3] = useState('');
  const [tecnica3, setTecnica3] = useState('');
  const [momento4, setMomento4] = useState('');
  const [tecnica4, setTecnica4] = useState('');
  const [momento5, setMomento5] = useState('');
  const [tecnica5, setTecnica5] = useState('');

  const navigate = useNavigate();
  const date = new Date()
  const { user } = useUserAuth();
  const formCollection = collection(db, "Higiene de manos")

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(formCollection, {
        servicio: servicio,
        cargo: cargo,
        observaciones: observaciones,
        aspecto1: aspecto1,
        aspecto2: aspecto2,
        aspecto3: aspecto3,
        aspecto4: aspecto4,
        aspecto5: aspecto5,
        momento1: momento1,
        tecnica1: tecnica1,
        momento2: momento2,
        tecnica2:tecnica2,
        momento3: momento3,
        tecnica3: tecnica3,
        momento4: momento4,
        tecnica4: tecnica4,
        momento5: momento5,
        tecnica5: tecnica5,
        date: date,
        user: user.email
      })
  .then(()=> {
    Swal.fire(
      '¡Buen trabajo!',
      'Los datos se han enviado exitosamente',
      'success'
    )
    .then(()=> {
      navigate('/feed');
    })
  });
  }
  
  return (
    <>
    <Topbar
      user='Hospital Civil de Ipiales'
    />
    <div className="form">
      <h2>Higiene de manos</h2>
      <div className="form__container">
        {/* <small>Llena todos los campos de hig</small> */}
        <form action="" onSubmit={handleSubmit}>
          <br />
          <label>Servicio</label>
            <select className='form__input' onChange={(e)=>setServicio(e.target.value)}>
              <option hidden defaultValue>Elige el servicio</option>
              <option value='Hospitalización Quirúrgicas'>Hospitalización Quiirúrgicos</option>
              <option value='Hospitalización general'>Hospitalización general</option>
              <option value='UCI Adultos'>UCI Adultos</option>
              <option value='Pediatría'>Pediatría</option>
              <option value='Ginecología'>Ginecología</option>
              <option value='Urgencias'>Urgencias</option>
              <option value='UCI neonatal'>UCI neonatal</option>
              <option value='Medicina interna'>Medicina interna</option>
              <option value='Cirugia'>Cirugia</option>
              <option value='Imagenología'>Imagenología</option>
              <option value='Laboratorio'>Laboratorio</option>
              <option value='Rehabolitación'>Rehabolitación</option>
              <option value='Urgencias pediátricas'>Urgencias pediátricas</option>
              <option value='Consulta externa'>Consulta externa</option>
              <option value='Atención de partos'>Atención de partos</option>
              <option value='Urgencias obstétricas'>Urgencias obstétricas</option>
              <option value='Otro'>Otro</option>
            </select>
          <h3>Aspectos a evaluar</h3>
          <br />
          <label>Hay dotación de Jabón, alcohol glicerinado y toallas desechables. (fecha de apertura de jabon y alcohol)</label>
            <select className='form__input' onChange={(e)=>setAspecto1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>El estado en el que se encuentran disponibles los elementos de dotación es el adecuado.</label>
            <select className='form__input' onChange={(e)=>setAspecto2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>Hay habladores en sitio sobre comorealizar la higiene de las manos.</label>
            <select className='form__input' onChange={(e)=>setAspecto3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>Hay habladores sobre los cinco momentos de higiene de manos </label>
            <select className='form__input' onChange={(e)=>setAspecto4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>Exite junto al lavamanos canecas de residuos ordinarios </label>
            <select className='form__input' onChange={(e)=>setAspecto5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <h3>Momentos de higiene de manos</h3>
          <br />
          <label>Antes de entrar en contacto directo con el paciente.</label>
            <select className='form__input' onChange={(e)=>setMomento1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>Técnica</label>
            <select className='form__input' onChange={(e)=>setTecnica1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Antes de realizar procedimiento invasivo.</label>
            <select className='form__input'  onChange={(e)=>setMomento2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Técnica</label>
            <select className='form__input'  onChange={(e)=>setTecnica2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Después de contacto con fluidos corporales</label>
            <select className='form__input'  onChange={(e)=>setMomento3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Técnica</label>
            <select className='form__input'  onChange={(e)=>setTecnica3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Después del contacto con el paciente</label>
            <select className='form__input'  onChange={(e)=>setMomento4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Técnica</label>
            <select className='form__input'  onChange={(e)=>setTecnica4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Despues del contacto con el entorno del paciente</label>
            <select className='form__input'  onChange={(e)=>setMomento5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Técnica</label>
            <select className='form__input'  onChange={(e)=>setTecnica5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
                  <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>Observaciones</label>
            <Input
              type='text'
              onChange={(e)=>setObservaciones(e.target.value)}
            />
            <label>Cargo evaluado</label>
            <select className='form__input' onChange={(e)=>setCargo(e.target.value)}>
              <option hidden defaultValue>Elige el cargo</option>
              <option value='Médico especialista'>Médico especialista</option>
              <option value='Médico general'>Médico general</option>
              <option value='Enfermera(o)'>Enfermera(o)</option>
              <option value='Auxuliar'>Auxuliar</option>
              <option value='Estudiante'>Estudiante</option>
              <option value='Docente'>Docente</option>
              <option value='Otras profesiones'>Otras profesiones</option>
            </select>
            <div className="form__btn">
              <Button
                title='Enviar'
              />
            </div>
        </form>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Form