import React from 'react';
import './Topbar.scss';

function Topbar({user}) {
  return (
    <>
        <header className='topbar'>
            <h2>Vieci<span>.</span></h2>
            <nav>
              <ul>
                <li>{user}</li>
              </ul>
            </nav>
        </header>
    </>
  )
}

export default Topbar