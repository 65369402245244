import React from 'react';
import '../App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserAuthContextProvider } from '../context/UserAuthContext';
import ProtectedRoute from '../providers/ProtectedRoute';
import Login from '../pages/Login/Login';
import Feed from '../pages/Feed/Feed';
import HigieneManos from '../pages/forms/higiene/Form';
import Asepsia from '../pages/forms/asepsia/Form';
import Soporte from '../pages/soporte/Soporte';
import Profile from '../pages/profile/Profile';
import Bienestar from '../pages/Bienestar/Bienestar';
function App() {
  return (
    <UserAuthContextProvider>
      <Router>
          <Routes>
            <Route exact path='/' element={<Login/>}/>
            <Route exact path='/feed' element={<ProtectedRoute><Feed/></ProtectedRoute> }/>
            <Route exact path='/higiene-manos' element={<ProtectedRoute> <HigieneManos/></ProtectedRoute>} />
            <Route exact path='/asepsia-antiasepsia' element={<ProtectedRoute><Asepsia/></ProtectedRoute>} />
            <Route exact path='/soporte' element={<ProtectedRoute><Soporte/></ProtectedRoute>} />
            <Route exact path='/perfil' element={<ProtectedRoute> <Profile/></ProtectedRoute>} />
            <Route exact path='/bienestar' element={<ProtectedRoute> <Bienestar/></ProtectedRoute>} />
          </Routes>
      </Router>
    </UserAuthContextProvider>
  );
}

export default App;
