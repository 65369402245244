import React, { useState }  from 'react';
import './Login.scss';
import { Link, useNavigate } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import { useUserAuth } from '../../context/UserAuthContext';
import { signInWithEmailAndPassword } from 'firebase/auth';


function Login() {

  const data = [
    {
      id: '1',
      label: 'Correo electrónico',
      type: 'email',
      placeholder: '',
      onChange: (e)=>setEmail(e.target.value)
    },
    {
      id: '2',
      label: 'Contraseña',
      type: 'password',
      placeholder: '',
      onChange: (e)=>setPassword(e.target.value)
    }
  ]

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState('');
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErr('')
    try {
      await logIn(email, password)
        .then(()=> navigate('/feed'))
    } catch(err) {
      setErr('Usuario o contraseña inválidos')
    } 
  }
  
  return (
    <>
    <Topbar
      user=''
    />
    <div className='login'>
      <div className='login__container'>
        <h2>Bienvenido a Vieci<span>.</span></h2>
        <div className="login__form">
          <form onSubmit={handleLogin}>
            {
              data.map(item => 
                <Input
                  id={item.id}
                  label={item.label}
                  type={item.type}
                  placeholder={item.placeholder}
                  onChange={item.onChange}
                />
              )
            }
            <div className="login__btn">
                <Button
                  title='Ingresar'
                />
                <h3>{err}</h3>
                <p>¿Olvidaste tu contraseña? <br /><a href="mailto:rafa@gmail.com?Subject=Interesado%20en%20el%20curso">Recuperala aquí</a></p>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export default Login