import React, { useState } from 'react';
import '../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import Navbar from '../../../components/navbar/Navbar';
import Button from '../../../components/button/Button';
import Swal from 'sweetalert2'
import { db } from '../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useUserAuth } from '../../../context/UserAuthContext';


function Form() {
  const [servicio, setServicio] = useState('');
  const [medidas1, setMedidas1] = useState('');
  const [medidas2, setMedidas2] = useState('');
  const [medidas3, setMedidas3] = useState('');
  const [medidas4, setMedidas4] = useState('');
  const [medidas5, setMedidas5] = useState('');
  const [medidas6, setMedidas6] = useState('');
  const [medidas7, setMedidas7] = useState('');
  const [medidas8, setMedidas8] = useState('');
  const [higiene1, setHigiene1] = useState('');
  const [higiene2, setHigiene2] = useState('');
  const [higiene3, setHigiene3] = useState('');
  const [dispositivos1, setDispositivos1] = useState('');
  const [dispositivos2, setDispositivos2] = useState('');
  const [dispositivos3, setDispositivos3] = useState('');
  const [aislamiento1, setAislamiento1] = useState('');
  const [aislamiento2, setAislamiento2] = useState('');
  const [aislamiento3, setAislamiento3] = useState('');
  const [aislamiento4, setAislamiento4] = useState('');
  const [aislamiento5, setAislamiento5] = useState('');
  const [aislamiento6, setAislamiento6] = useState('');
  const [aislamiento7, setAislamiento7] = useState('');
  const [aislamiento8, setAislamiento8] = useState('');
  const [insumos1, setInsumos1] = useState('');
  const [insumos2, setInsumos2] = useState('');
  const [insumos3, setInsumos3] = useState('');
  const [instrumentos1, setInstrumentos1] = useState('');
  const [instrumentos2, setInstrumentos2] = useState('');
  const [instrumentos3, setInstrumentos3] = useState('');
  const [instrumentos4, setInstrumentos4] = useState('');
  const [instrumentos5, setInstrumentos5] = useState('');
  const [instrumentos6, setInstrumentos6] = useState('');
  const [instrumentos7, setInstrumentos7] = useState('');
  const [instrumentos8, setInstrumentos8] = useState('');
  const [instrumentos9, setInstrumentos9] = useState('');
  const [instrumentos10, setInstrumentos10] = useState('');
  const [instrumentos11, setInstrumentos11] = useState('');
  const [estado1, setEstado1] = useState('');
  const [estado2, setEstado2] = useState('');
  const [ropa1, setRopa1] = useState('');
  const [ropa2, setRopa2] = useState('');
  const [ropa3, setRopa3] = useState('');
  const [ropa4, setRopa4] = useState('');
  const [limpieza1, setLimpieza1] = useState('');
  const [limpieza2, setLimpieza2] = useState('');
  const [limpieza3, setLimpieza3] = useState('');



  const navigate = useNavigate();
  const date = new Date()
  const { user } = useUserAuth();
  const formCollection = collection(db, "Asepsia y antiasepsia")

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(formCollection, {
        user: user.email,
        servicio: servicio,
        medidas1: medidas1,
        medidas2: medidas2,
        medidas3: medidas3,
        medidas4: medidas4,
        medidas5: medidas5,
        medidas6: medidas6,
        medidas7: medidas7,
        medidas8: medidas8,
        higiene1: higiene1,
        higiene2: higiene2,
        higiene3: higiene3,
        dispositivos1: dispositivos1,
        dispositivos2: dispositivos2,
        dispositivos3: dispositivos3,
       aislamiento1: aislamiento1, 
       aislamiento2: aislamiento2, 
       aislamiento3: aislamiento3, 
       aislamiento4: aislamiento4, 
       aislamiento5: aislamiento5, 
       aislamiento6: aislamiento6, 
       aislamiento7: aislamiento7, 
       aislamiento8: aislamiento8, 
       insumos1: insumos1,
       insumos2: insumos2,
       insumos3: insumos3,
       instrumentos1: instrumentos1,
       instrumentos2: instrumentos2,
       instrumentos3: instrumentos3,
       instrumentos4: instrumentos4,
       instrumentos5: instrumentos5,
       instrumentos6: instrumentos6,
       instrumentos7: instrumentos7,
       instrumentos8: instrumentos8,
       instrumentos9: instrumentos9,
       instrumentos10: instrumentos10,
       instrumentos11: instrumentos11,
       estado1: estado1,
       estado2: estado2,
       ropa1: ropa1,
       ropa2: ropa2,
       ropa3: ropa3,
       ropa4: ropa4,
       limpieza1: limpieza1,
       limpieza2: limpieza2,
       limpieza3: limpieza3,
       
      date: date,
      })
  .then(()=> {
    Swal.fire(
      '¡Buen trabajo!',
      'El formulario ha sido enviado exitosamente',
      'success'
    )
    .then(()=> {
      navigate('/feed');
    })
  });
  }
  
  return (
    <>
    <Topbar
      user='Hospital Civil de Ipiales'
    />
    <div className="form">
      <h2>Asepsia y antiasepsia</h2>
      <div className="form__container">
        {/* <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus iste.</small> */}
        <form action="" onSubmit={handleSubmit}>
          <br />
          <label>Servicio</label>
            <select className='form__input' onChange={(e)=>setServicio(e.target.value)}>
            <option hidden defaultValue>Elige el servicio</option>
              <option value='Hospitalización'>Hospitalización</option>
              <option value='UCI Adultos'>UCI Adultos</option>
              <option value='Pediatría'>Pediatría</option>
              <option value='Ginecología'>Ginecología</option>
              <option value='Urgencias'>Urgencias</option>
              <option value='UCI neonatal'>UCI neonatal</option>
              <option value='Medicina interna'>Medicina interna</option>
              <option value='Cirugia'>Cirugia</option>
              <option value='Imagenología'>Imagenología</option>
              <option value='Laboratorio'>Laboratorio</option>
              <option value='Rehabolitación'>Rehabolitación</option>
              <option value='Urgencias pediátricas'>Urgencias pediátricas</option>
              <option value='Consulta externa'>Consulta externa</option>
            </select>
          <h3>Medidas generales de asepsia</h3>
          <br />
          <label>1. Utiliza correctamente el uniforme o blusa</label>
            <select className='form__input' onChange={(e)=>setMedidas1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>2. Lleva el cabello recogido</label>
            <select className='form__input' onChange={(e)=>setMedidas2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
              <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>3. Tiene las uñas cortas y sin pintura</label>
            <select className='form__input' onChange={(e)=>setMedidas3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>4. No utiliza durante su trabajo anillos, pulseras y/o collares</label>
            <select className='form__input' onChange={(e)=>setMedidas4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>5. Realiza limpieza en area de trabajo y/o carro de curaciones </label>
            <select className='form__input' onChange={(e)=>setMedidas5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>6. A primera hora de la mañana</label>
            <select className='form__input' onChange={(e)=>setMedidas6(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
          <label>7. Durante el turno</label>
            <select className='form__input' onChange={(e)=>setMedidas7(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>8. Al finalizar el turno</label>
            <select className='form__input'  onChange={(e)=>setMedidas8(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Lavado e higiene de manos</h3>
           <br />
            <label>9. Conoce y aplica los 5 momentos para el lavado de manos.</label>
            <select className='form__input'  onChange={(e)=>setHigiene1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>10. Realiza técnica adecuada para higiene y lavado de manos</label>
            <select className='form__input'  onChange={(e)=>setHigiene2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
        <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>11. Conoce el porcentaje de adherencia actual de lavado de manos de su proceso e institucional</label>
            <select className='form__input'  onChange={(e)=>setHigiene3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
        <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Dispositivos invasivos</h3>
           <br />
            <label>12. Dispositivos médicos (catéteres, drenes, sondas) fijados y marcados según protocolo institucional: Fecha (de inserción en cistiflo de sonda vesical y en catéter periférico y de inserción y curación en CV central y línea arterial) y nombre del funcionario responsable.  Para los periféricos se anota el calibre del catéter.</label>
            <select className='form__input'  onChange={(e)=>setDispositivos1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
        <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>13. Información al paciente sobre los cuidados y riesgos en la manipulación de los dispositivos y equipos médicos (catéteres, drenes, sondas, bombas de infusión) según lo normalizado en la Institución.</label>
            <select className='form__input'  onChange={(e)=>setDispositivos2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
        <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>14. Líquidos endovenosos instalados, marcados adecuadamente (nombre completo del paciente, solución contenida, fecha y hora de inicio, goteo/minuto, tiempo para administración, nombre del funcionario responsable), en UCI y UCIN líquidos y soluciones deben estar rotuladas con (nombre completo del paciente, número de identificación, cubículo, medicamento o soluto, líquido en el cual se diluye, fecha y hora de preparación).</label>
            <select className='form__input'  onChange={(e)=>setDispositivos3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Medidas de aislamiento</h3>
            <br />
            <label>15. El area de aislamiento cuenta con los requerimientos e insumos necesarios  (sistema de ventilacion, tapabocas, guantes, bata, puerta cerrada.)</label>
            <select className='form__input'  onChange={(e)=>setAislamiento1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>16. Se identifica el riesgo  de aislamiento en la historia clinica </label>
            <select className='form__input' onChange={(e)=>setAislamiento2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>17. Aplicación de normas de bioseguridad  para el manejo de los pacientes  en areas de aislamiento   </label>
            <select className='form__input' onChange={(e)=>setAislamiento3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>18. Se evidencia uso adecuado de elementos de protección personal respectivos. </label>
            <select className='form__input' onChange={(e)=>setAislamiento4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>19. Los familiares de los pacientes  conocen las medidas de prevencion de  las areas de aislamiento  </label>
            <select className='form__input' onChange={(e)=>setAislamiento5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>20. ¿Cuáles son los colores que identifican los 4 tipos de aislamiento en el hospital? </label>
            <select className='form__input' onChange={(e)=>setAislamiento6(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>21. Se dispone de ropa y elementos de protección personal suficientes para ingresar a la habitación de aislamiento.  </label>
            <select className='form__input' onChange={(e)=>setAislamiento7(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>22. Existe manejo adecuado de residuos en los pacientes con aislamiento. (verifique solo debe haber caneca roja, manejo de vajilla y sobras de alimentos.</label>
            <select className='form__input' onChange={(e)=>setAislamiento8(e.target.value)}>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Manejo de insumos esteriles</h3>
            <br />
            <label>23. Comprueba la integridad de todo paquete estéril.</label>
            <select className='form__input' onChange={(e)=>setInsumos1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>24. Comprueba la integridad de todo paquete estéril.</label>
            <select className='form__input' onChange={(e)=>setInsumos2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>25. Colocación de guantes con técnica abierta.</label>
            <select className='form__input' onChange={(e)=>setInsumos3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Instrumentos y/o  incluye adminstración de medicamentos</h3>
            <br />
            <label>26. Lavado quirurgico de manos.</label>
            <select className='form__input' onChange={(e)=>setInstrumentos1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>27. Arreglo de mesas quirurgicas.</label>
            <select className='form__input' onChange={(e)=>setInstrumentos2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>28. Vestimenta quirurgica.</label>
            <select className='form__input' onChange={(e)=>setInstrumentos3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>29. Colocación de guantes con técnica cerrada</label>
            <select className='form__input' onChange={(e)=>setInstrumentos4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>30. Colocación de guantes al cirujano</label>
            <select className='form__input' onChange={(e)=>setInstrumentos5(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>31. Mantiene la distancia prudente con relacion a las mesas</label>
            <select className='form__input' onChange={(e)=>setInstrumentos6(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>32. Maneja las normas para mantener la asepsia durante la cirugia y/o procedimiento</label>
            <select className='form__input' onChange={(e)=>setInstrumentos7(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>33. El campo de inyectologia esta limpio</label>
            <select className='form__input' onChange={(e)=>setInstrumentos8(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>34. Los cateteres de via intravenosa estan con espradrapo limpios, secos y rotulados.</label>
            <select className='form__input' onChange={(e)=>setInstrumentos9(e.target.value)}>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>35. Registro de preparación prequirúrgica, correctamente diligenciada en SIHOS (baño corporal, profilaxis antibiótica, asepsia prequirúrgica, entre otras).</label>
            <select className='form__input' onChange={(e)=>setInstrumentos10(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>36.Conoce y aplica el instructivo de Manejo de Heridas Abiertas y Prevención de lesiones en piel.</label>
            <select className='form__input' onChange={(e)=>setInstrumentos11(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Estado de soluciones y recipientes</h3>
            <br />
            <label>37. Soluciones revisadas en cada turno, debidamente rotuladas con fecha de activación y vencimiento</label>
            <select className='form__input' onChange={(e)=>setEstado1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>38. Recipientes o áreas destinadas para insumos o medicamentos debidamente rotulados, en buen estado higiénico y funcional.</label>
            <select className='form__input' onChange={(e)=>setEstado2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Manejo de ropa sucia y deshechos</h3>
            <br />
            <label>39. Clasifica de acuerdo a la contaminación y tipo de aislamiento.</label>
            <select className='form__input' onChange={(e)=>setRopa1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>40. Deposita la ropa en las canecas indicadas.</label>
            <select className='form__input' onChange={(e)=>setRopa2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>41. Utiliza adecuadamente la ropa institucional</label>
            <select className='form__input' onChange={(e)=>setRopa3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>42. Realiza una adecuada clasificación de los desechos en los respectivas canecas.</label>
            <select className='form__input' onChange={(e)=>setRopa4(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <h3>Limpieza y desinfección</h3>
            <br />
            <label>43. El entorno del paciente se encuentra visiblemente limpio y ordenad.</label>
            <select className='form__input' onChange={(e)=>setLimpieza1(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>44. Conoce el orden de desinfección del entorno del paciente y mobiliario (desde lo más limpio a lo más contaminado).</label>
            <select className='form__input' onChange={(e)=>setLimpieza2(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <label>45.conoce los productos para limpieza y desinfección y su respectiva dilución.</label>
            <select className='form__input' onChange={(e)=>setLimpieza3(e.target.value)}>
            <option hidden defaultValue>Elige la opción</option>
          <option value='👍 Cumple'>👍 Cumple</option>
              <option value='👎 No cumple'>👎 No cumple</option>
              <option value='🤲 No aplica'>🤲 No aplica</option>
            </select>
            <div className="form__btn">
              <Button
                title='Enviar'
              />
            </div>
        </form>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Form