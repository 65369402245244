import React from 'react';
import './Profile.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import Button from '../../components/button/Button';
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { useUserAuth } from '../../context/UserAuthContext';
import { BsEmojiSmile } from "react-icons/bs";

function Profile() {

  const { user, logOut } = useUserAuth();

  const handlelogOut = async () => {
    try {
      await logOut();
    } catch(err) {
      console.log(err)
    }
  }


  return (
    <>
    <Topbar
       user='Hospital Civil de Ipiales'
    />
    <div className="profile">
        <h2>Perfil</h2>
        <div className="profile__container">
          <label htmlFor="">Correo</label>
          <input type='text'  className='input' placeholder='' value={user.email}  readOnly />
          <label htmlFor="">Institución</label>
          <input type='text'  className='input' placeholder='' value='Hospital Civil de Ipiales' readOnly />
          <Link to='/bienestar' style={{textDecoration:'none'}}>
            <Card
              icon={<BsEmojiSmile/>}
              title='Bienestar'
              description='¿Cómo te sentiste hoy?'
            />
          </Link>
          <Button
      
            title='Cerrar sesión'
            onClick={handlelogOut}
          />
        </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Profile;